import React from "react"
import Layout from "../components/layout"
import Header1 from "../components/header1"
import CompanyJSON from "../data/company.json"
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";

export default function Imprint() {
  return (
    <>
      <GatsbySeo title="Legal Notice"
                 description={`LinkFive is a product of ${CompanyJSON.companyName}. ${CompanyJSON.companyName}, ${CompanyJSON.street}, ${CompanyJSON.postcode} ${CompanyJSON.city}, ${CompanyJSON.country}`}
      />
      <Layout fullWave="false" bottomWave="false" callToAction="true">
        <section className="bg-white py-20">
          <div className="container max-w-5xl mx-auto m-8">
            <Header1 headerText="Legal Notice"/>
          </div>

          <div className="max-w-5xl mx-auto m-8 text-gray-500 md:text-xl">

            <p>LinkFive is a product of {CompanyJSON.companyName}</p>
            <br/>
            <p>Disclosures in accordance with § 5 TMG:</p>
            <br/>
            <p>{CompanyJSON.companyName}</p>
            <p>{CompanyJSON.street}</p>
            <p>{CompanyJSON.postcode} {CompanyJSON.city}</p>
            <p>{CompanyJSON.country}</p>
            <br/>
            <p>E-Mail: {CompanyJSON.email}</p>
            <p>Tel: {CompanyJSON.phone}</p>
            <br/>
            <p>Registry court: Amtsgericht München</p>
            <p>Register number: HRB 262823</p>
            <p>VAT ID number in accordance with {String.fromCharCode(167)} 27 UStG: DE309403077</p>

            <br/>
            <p>Authorized representatives: {CompanyJSON.representatives}</p>
          </div>

        </section>
      </Layout>
    </>
  )
}